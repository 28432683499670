import React, { useState, useEffect } from "react";
import constants from "../utils/constants";
import { StyleSheet, ActivityIndicator, Text, View, Pressable, TextInput, Linking, Platform } from 'react-native'
import { signInWithEmailAndPassword } from "firebase/auth";
import { db, auth } from '../db/firestore';
import { doc, updateDoc, getDoc } from "firebase/firestore";

const LogIn = ({ navigation }) => {
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if (user) {
                navigation.replace("Home", {
                    email: user.email,
                })
                setLoading(false);
            } else {
                setLoading(false);
            }
        }, error => {
            setLoading(false);
        })
    }, [])

    const handleSubmit = async () => {
        setLoading(true)
        const userRef = doc(db, "users", email);
        
        // Fetch the loggedInDevices field only
        const userSnapshot = await getDoc(userRef);
        
        if (userSnapshot.exists()) {
            const user = userSnapshot.data();
            const loggedInDevices = user.loggedInDevices || 0;
            
            if (loggedInDevices < 2) {
                signInWithEmailAndPassword(auth, email, password)
                    .then(async (userCredential) => {
                        // Increment loggedInDevices by 1
                        await updateDoc(userRef, {
                            loggedInDevices: loggedInDevices + 1
                        });
                        setLoading(false);
    
                        // Navigate to Home screen
                        const user = userCredential.user;
                        navigation.replace("Home", {
                            email: user.email,
                        });
                    })
                    .catch((error) => {
                        setLoading(false);
                        setErrorMessage("Username or Password Invalid");
                    });
            } else {
                setLoading(false);
                setErrorMessage("Max number of devices already signed in");
            }
        } else {
            setLoading(false);
            setErrorMessage("Username or Password Invalid");
        }
    }

    return (

        <View style={styles.centeredView}>
            {loading ?
                <ActivityIndicator size="large" color={constants.ICON_COLOR} />
                : <View style={styles.modalView}>
                    <Text style={styles.loginText}>Please Log In</Text>
                    <TextInput
                        style={styles.input}
                        autoCorrect={false}
                        autoCapitalize={'none'}
                        keyboardType={'email-address'}
                        onChangeText={setEmail}
                        value={email}
                        placeholder="Username"
                    />
                    <TextInput
                        style={styles.input}
                        autoCorrect={false}
                        autoCapitalize={'none'}
                        secureTextEntry={true}
                        onChangeText={setPassword}
                        value={password}
                        placeholder="Password"
                    />
                    {errorMessage ? <Text style={{ marginBottom: 10, color: 'red' }}>{errorMessage}</Text> : null}
                    <Pressable
                        style={[styles.button, styles.buttonClose]}
                        onPress={handleSubmit}
                    >
                        <Text style={styles.textStyle}>Submit</Text>
                    </Pressable>
                    <Pressable onPress={() => navigation.navigate('PasswordReset')}>
                        <Text style={[styles.text, { color: constants.ICON_COLOR, marginTop: 8 }]}>Forgot Password?</Text>
                    </Pressable>
                </View>}

        </View>
    )
}

export default LogIn

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        fontFamily: 'Verdana',
        paddingHorizontal: 20, // To ensure content is padded on smaller screens
    },
    loginText: {
        fontFamily: 'Verdana',
        fontWeight: 'bold',
        fontSize: 18, // Adjusted font size for better readability on mobile
    },
    modalView: {
        fontFamily: 'Verdana',
        margin: 20,
        width: "80%", // Default width for mobile screens
        maxWidth: 400, // Optional limit for larger screens
        backgroundColor: "white",
        borderRadius: 20,
        padding: 26,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    button: {
        borderRadius: 20,
        padding: 10,
        elevation: 2,
    },
    buttonOpen: {
        backgroundColor: constants.PRIMARY_DARK,
    },
    buttonClose: {
        backgroundColor: constants.ICON_COLOR,
        paddingHorizontal: 25,
    },
    textStyle: {
        color: "white",
        fontWeight: "bold",
        textAlign: "center",
        fontFamily: 'Verdana',
    },
    modalText: {
        marginBottom: 15,
        textAlign: "center",
        fontFamily: 'Verdana',
    },
    input: {
        height: 40,
        width: "100%", // Full width inside the container
        marginVertical: 10,
        borderWidth: 0.5,
        padding: 10,
        borderRadius: 10,
        fontFamily: 'Verdana',
    },
});