import { db } from '../../db/firestore';
import { doc, getDoc, getDocs, collection } from "firebase/firestore";
import constants from "../../utils/constants";
import { StyleSheet, Text, View, Pressable, Platform } from 'react-native'
import { EvilIcons, Ionicons, AntDesign, MaterialIcons } from '@expo/vector-icons';

const weekdayArr = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
const monthNameArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',];

const getStatAgs = async (type) => {
    const docRef = collection(db, `${type}`);
    return await getDocs(docRef)
        .then(result => result.docs)
        .then(docs => docs.map(doc => doc.data()))
        .then(docs => docs.map(doc => {
            // Convert UTC to local time
            const startTimeUTC = doc.start ? new Date(doc.start) : new Date("2030-01-01T09:00:41Z");
            const localDate = startTimeUTC.toLocaleDateString();
            const localTime = startTimeUTC.toLocaleString([], { hour: '2-digit', minute: '2-digit', hour12: true });

            return {
                subject: doc.subject,
                noDate: doc.noDate ? doc.noDate : doc.start ? false : true,
                uponAdjourn: doc.uponAdjourn ? doc.uponAdjourn : false,
                adjournText: doc.adjournText ? doc.adjournText : null,
                start: doc.start ? doc.start : null,
                date: localDate,
                time: localTime,
                id: doc.id,
                location: doc.location,
                livestreamUrl: doc.livestreamUrl,
                statAgs: doc.statAgs,
            };
        }))
};

const getTrackedStatAgs = async (email, statAgs) => {
    const docRef = doc(db, "users", email);
    return await getDoc(docRef)
        .then(doc => doc.data())
        .then(data => {
            const statAgsObjArr = [];
            for (let i = 0; i < data.trackedStatAgs.length; i += 1) {
                const statAgArr = statAgs.filter(x => x.statAgs?.includes(data.trackedStatAgs[i]));
                if (statAgArr.length > 0) {
                    statAgsObjArr.push(...statAgArr)
                }
            }
            const uniqueStatAgsObjArr = Array.from(new Map(statAgsObjArr.map(item => [item.id, item])).values());
            return uniqueStatAgsObjArr;
        })
}

const fillTable = (statAgData, navigation, email) => {
    const dataArr = [];
    if (Array.isArray(statAgData)) {
        for (let i = 0; i < statAgData.length; i += 1) {
            const statAgNames = []
            for (let j = 0; j < statAgData[i].statAgs.length; j += 1) {
                statAgNames.push(<Text key={`${i}-${j}`} style={styles.textStyle}>{statAgData[i].statAgs[j]}</Text>)
            }
            let dateTimeString;
            let noStart = false;
            if (statAgData[i].noDate && statAgData[i].start == null) {
                dateTimeString = new Date("2030-05-07T09:00:41Z")
                noStart = true
            } else {
                dateTimeString = new Date(statAgData[i].start);
            }
            const weekday = weekdayArr[dateTimeString.getDay()];
            const month = monthNameArr[dateTimeString.getMonth()];
            const dateNum = dateTimeString.getDate();
            let formattedTime = statAgData[i].time;
            const isNoDate = statAgData[i].noDate ? statAgData[i].noDate : false;
            const isUponAdjourn = statAgData[i].uponAdjourn ? statAgData[i].uponAdjourn : false;
            if (isNoDate) {
                formattedTime = "(TBD)";
            }
            if (isUponAdjourn) {
                formattedTime = statAgData[i].adjournText ? `${statAgData[i].adjournText}` : "(TBD)";
            }
            const statAgObj =
                <Pressable
                    key={i}
                    style={[styles.button, styles.buttonClose]}
                    onPress={() => navigation.navigate('StateAgencyInfo', { title: statAgData[i].name, id: statAgData[i].id, options: { headerBackTitleVisible: false } })}
                >
                    <View style={styles.rowContainer}>
                        <View style={[styles.calendarContainer]}>
                            <View style={styles.calendarMonth}>
                                <Text style={styles.monthText}>{noStart ? "No" : month}</Text>
                            </View>
                            <View style={[styles.calendarDate]}>
                                <Text style={styles.weekdayText}>{noStart ? null : weekday}</Text>
                                <Text style={[styles.dateText, { fontSize: noStart ? 11.5 : 16, marginTop: noStart ? 10 : 0 }]}>{noStart ? 'Date' : dateNum}</Text>
                            </View>
                        </View>
                        <View style={{ width: '98%' }}>
                            {statAgNames}
                            <View style={styles.rowContainer}>
                                <MaterialIcons name="subject" size={20} color="#e5e5e5" style={{ marginTop: 3, marginBottom: 0, marginRight: 3, marginLeft: 3 }} />
                                <Text style={{ ...styles.subjectText }}>{statAgData[i].subject}</Text>
                            </View>
                            {statAgData[i].location ?
                                <View style={styles.rowContainer}>
                                    <EvilIcons name="location" size={22} color="white" style={{ marginTop: 3, marginBottom: 4, marginRight: 3 }} />
                                    <Text style={{ ...styles.locationText }}>{statAgData[i].location}</Text>
                                </View>
                                :
                                null
                            }
                            {isNoDate ?
                                null
                                :
                                <View style={styles.rowContainer}>
                                    <Ionicons name="time-outline" size={17} color="white" style={{ marginTop: -1.5, marginLeft: 4, marginRight: 3 }} />
                                    <Text style={{ ...styles.subTextStyle, marginTop: 0 }}>{formattedTime}</Text>
                                </View>
                            }
                        </View>
                    </View>
                </Pressable>
            dataArr.push(statAgObj)
        }
        if (dataArr.length > 0) {
            return dataArr
        } else {
            return (
                <View style={[styles.rowContainer, { alignSelf: 'center', width: '98%', margin: 30 }]}>
                    <AntDesign name="exclamationcircleo" size={20} color={constants.ICON_COLOR} />
                    <Text style={styles.noEntriesText}> There are no entries for your selection!</Text>
                </View>
            )
        }
    } else {
        return (
            <View style={[styles.rowContainer, { alignSelf: 'center', width: '98%', margin: 30 }]}>
                <AntDesign name="exclamationcircleo" size={20} color={constants.ICON_COLOR} />
                <Text style={styles.noEntriesText}> There are no entries for your selection!</Text>
            </View>
        )
    }
}

const getPastStatAgs = (data) => {
    const filteredData = data.filter(item => {
        let currentDate = item.start ? new Date(item.start) : new Date("2030-05-07T09:00:41Z");
        return currentDate < Date.now();
    });
    return filteredData.sort(function (a, b) {
        return new Date(b.start) - new Date(a.start);
    });
}

const getCurrentStatAgs = (data) => {
    const filteredData = data.filter(item => {
        let currentDate = item.start ? new Date(item.start) : new Date("2030-05-07T09:00:41Z");
        return currentDate >= Date.now();
    });
    return filteredData.sort(function (a, b) {
        return new Date(a.start) - new Date(b.start);
    });
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: constants.PRIMARY_DARK,
        marginTop: 20,
    },
    dropdown: {
        height: 40,
        width: 170,
        borderColor: 'gray',
        borderWidth: 0.5,
        borderRadius: 8,
        paddingHorizontal: 8,
        marginBottom: 10,
    },
    listItems: {
        backgroundColor: "#D3D3D3",
        borderWidth: 1,
        marginLeft: 15,
        marginRight: 15,
        marginBottom: 6,
    },
    scrollView: {
        marginBottom: 100
    },
    button: {
        borderRadius: 9,
        borderWidth: 3,
        borderColor: 'black',
        paddingTop: 10,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 12,
        elevation: 2,
        alignSelf: 'center',
        width: "98%",
        flexGrow: 1,
        marginBottom: 1,
    },
    trackedButtons: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    buttonOpen: {
        backgroundColor: "white",
    },
    buttonClose: {
        backgroundColor: constants.SECONDARY_DARK,
    },
    calendarContainer: {
        backgroundColor: 'white',
        width: 48.5,
        borderColor: 'black',
        borderWidth: 2.65,
        borderRadius: 5,
        marginBottom: 10,
        marginRight: 7,
        marginTop: 4,
        ...Platform.select({
            ios: {
                height: 51.5,
            },
            android: {
                height: 53.5,
            },
            web: {
                height: 53.5,
            }
        })
    },
    calendarMonth: {
        backgroundColor: '#D21F3C',
        height: 18,
        borderColor: 'gray',
        borderWidth: .09,
        borderBottomColor: 'black',
        borderBottomWidth: 1.6,
        borderTopRightRadius: 2.5,
        borderTopLeftRadius: 2.5,
        alignItems: 'center',
    },
    calendarDate: {
        alignItems: 'center',
    },
    monthText: {
        color: 'white',
        fontWeight: 'bold',
        fontFamily: 'Verdana',
        fontSize: 12
    },
    weekdayText: {
        color: "#373737",
        marginBottom: -4,
        fontSize: 11,
        fontWeight: "bold",
    },
    dateText: {
        fontWeight: 'bold',
        fontFamily: 'Verdana',
        fontSize: 16,
    },
    subjectText: {
        color: "#e5e5e5",
        marginTop: 3,
        marginBottom: 0,
        fontSize: 12,
        fontWeight: "bold",
    },
    locationText: {
        color: "#e5e5e5",
        marginTop: 3,
        marginBottom: 6,
        fontSize: 11,
        fontWeight: "bold",
    },
    textStyle: {
        color: constants.TITLE_COLOR,
        marginBottom: 2,
        marginLeft: 2,
        fontSize: 14,
        fontWeight: "bold",
        textAlignVertical: "center",
        ...Platform.select({
            ios: {
                lineHeight: 20
            },
            android: {}
        })
    },
    subTextStyle: {
        color: constants.SUBTEXT_COLOR,
        marginBottom: 0,
        fontSize: 11,
        fontWeight: "bold",
    },
    noEntriesText: {
        color: 'white',
        fontSize: 14,
        fontWeight: "bold",
        fontFamily: 'Verdana',
        textAlign: 'center',
        textAlignVertical: "center",
        ...Platform.select({
            ios: {
                lineHeight: 20
            },
            android: {}
        })
    },
    rowContainer: {
        flexDirection: 'row',
        flexGrow: 1,
        width: "90%",
    },
    icon: {
        color: 'white',
        marginRight: 5,
    },
    label: {
        position: 'absolute',
        backgroundColor: 'white',
        left: 22,
        top: 8,
        zIndex: 999,
        paddingHorizontal: 8,
        fontSize: 14,
    },
    placeholderStyle: {
        fontSize: 16,
    },
    selectedTextStyle: {
        fontSize: 16,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
})


export {
    getStatAgs,
    getTrackedStatAgs,
    fillTable,
    getPastStatAgs,
    getCurrentStatAgs,
}