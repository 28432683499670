import React from 'react';
import { Modal, View, Text, FlatList, TouchableOpacity, TouchableWithoutFeedback, StyleSheet, Dimensions } from 'react-native';
import { useNavigation, CommonActions } from '@react-navigation/native';
import { FontAwesome, AntDesign } from '@expo/vector-icons';
import { db } from '../../db/firestore';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import constants from '../../utils/constants';

const { width: screenWidth } = Dimensions.get("window");

const NotificationsModal = ({
    visible,
    onClose,
    currentNotifications,
    onNotificationPress,
    email,
}) => {
    const navigation = useNavigation();

    const handlePress = async (notification) => {
        let options;
        if (notification.screen === 'Meeting') {
            options = {
                title: "Meeting Info",
                id: notification.id,
                page: notification.page,
                options: { headerBackTitleVisible: false }
            }
        } else if (notification.screen === 'ExternalView') {
            options = {
                title: "Calendar Info",
                link: notification.link,
                calendar: notification.calendar,
                options: { headerBackTitleVisible: false }
            }
        } else if (notification.screen === 'Bill') {
            options = {
                title: notification.title,
                billId: notification.billId,
                options: { headerBackTitleVisible: false }
            }
        } else if (notification.screen === 'StateAgencyInfo') {
            options = {
                id: notification.id,
                options: { headerBackTitleVisible: false }
            }
        } else if (notification.screen === 'Home') {
            options = {}
        }

        const updatedNotifications = currentNotifications.map(item => {
            if (item.notificationId === notification.notificationId) {
                return { ...item, unread: false };
            }
            return item;
        });

        // Navigate to the target screen and reset the navigation stack
        if (notification.screen !== 'Home') {
            navigation.dispatch(
                CommonActions.reset({
                    index: 1,
                    routes: [
                        { name: 'Home' },  // Set 'Home' as the base route
                        { name: notification.screen, params: options },  // Push the target screen
                    ],
                })
            );
        } else {
            navigation.navigate(notification.screen, options);
        }
        onNotificationPress()

        const userRef = doc(db, "users", email);
        await updateDoc(userRef, {
            currentNotifications: updatedNotifications,
        });
    }

    return (
        <Modal
            animationType="fade"
            transparent={true}
            visible={visible}
            onRequestClose={onClose}
        >
            <TouchableWithoutFeedback onPress={onClose}>
                <View style={styles.modalContainer}>
                    <TouchableWithoutFeedback>
                        <View style={[
                            styles.modalContent,
                            { width: screenWidth > 600 ? '35%' : '90%' } // Responsive width
                        ]}>
                            {/* Close Icon in the top-right corner */}
                            <TouchableOpacity onPress={onClose} style={styles.closeButton}>
                                <AntDesign name="close" size={22} color={constants.ICON_COLOR} />
                            </TouchableOpacity>
                            <Text style={styles.modalTitle}>Notifications</Text>
                            <FlatList
                                data={currentNotifications}
                                keyExtractor={(item, index) => index.toString()}
                                renderItem={({ item, index }) => (
                                    <TouchableOpacity
                                        style={[
                                            styles.notificationItem,
                                            { backgroundColor: item.unread ? '#141414' : '#1b1c1c' },
                                            index === 0 && styles.firstItem,
                                            index === currentNotifications.length - 1 && styles.lastItem,
                                        ]}
                                        onPress={() => handlePress(item)}
                                    >
                                        {item.unread && (
                                            <FontAwesome name="circle" size={12} color={constants.ICON_COLOR} style={styles.unreadIndicator} />
                                        )}
                                        <Text style={[styles.notificationText, { marginLeft: item.unread ? 0 : 20 }]}>{item.message}</Text>
                                    </TouchableOpacity>
                                )}
                            />
                        </View>
                    </TouchableWithoutFeedback>
                </View>
            </TouchableWithoutFeedback>
        </Modal>
    );
};

export default NotificationsModal;

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContent: {
        maxHeight: '80%',
        minHeight: '40%',
        backgroundColor: constants.SECONDARY_DARK,
        borderRadius: 10,
        padding: 8,
    },
    closeButton: {
        position: 'absolute',
        top: 10,
        right: 15,
        zIndex: 1,
    },
    modalTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#e5e5e5',
        textAlign: 'center',
        marginVertical: 10,
    },
    notificationItem: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 10,
        paddingBottom: 20,
        paddingLeft: 8,
        borderBottomWidth: 0.5,
        borderBottomColor: 'black',
    },
    unreadIndicator: {
        marginRight: 10,
    },
    notificationText: {
        fontSize: 16,
        color: '#e5e5e5',
    },
    firstItem: {
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
    },
    lastItem: {
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
    },
});